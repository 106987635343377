import React, { useContext, useState } from "react";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";
import { BasicPopup } from "./BasicPopup";
import { askAPI, RequestMethod } from "../utils/api";
import discord from "../Menu/discord.png";

export const RedeemPopup = () => {
  const { dispatch } = useContext(AppContext);
  const [code, setCode] = useState("");

  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_REDEEM_POPUP });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await askAPI<{ message: string }>(
      `/api/redeem?code=${code}`,
      RequestMethod.GET
    );
    setCode("");
  };

  return (
    <BasicPopup onClose={closePopup}>
      <div className="font-bold text-lg mb-6">Redeem Code</div>
      <div>
        Redeem a code to get a reward. Codes are given out during events or
        promotions.
      </div>
      <div className="mt-4">
        Join us on{" "}
        <a
          href="https://discord.gg/yqkuuXnt2N"
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
        >
          <img
            src={discord}
            alt=""
            width={20}
            className="inline-block mr-1 mt-[-3px]"
          />
          Discord
        </a>{" "}
        to get your first code!
      </div>
      <form onSubmit={handleSubmit} className="mb-6 mt-6">
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter your code"
          className="px-2 p-1 mr-2 outline-none"
        />
        <button type="submit" className="button mb-1 px-2 py-1 ml-1  text-xs">
          Redeem
        </button>
      </form>
    </BasicPopup>
  );
};
