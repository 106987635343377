import axios from "axios";
import { SystemMessage } from "../types";

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
}

export const askAPI = async <T>(
  url: string,
  method: RequestMethod,
  body?: any
): Promise<T | null> => {
  if (method === RequestMethod.GET) {
    const response = await axios.get<T>(url, { params: body });
    return checkForSystemMessage(response.data);
  } else if (method === RequestMethod.POST) {
    const response = await axios.post<T>(url, body);
    return checkForSystemMessage(response.data);
  }
  return null;
};

const checkForSystemMessage = (responseData: any) => {
  if (responseData.systemMessage) {
    systemMessageSubsctriber(responseData);
    return responseData.data;
  } else {
    return responseData;
  }
};

let systemMessageSubsctriber: (message: SystemMessage) => void = () => {};
export const subscribeToSystemMessages = (
  callback: (message: SystemMessage) => void
) => {
  systemMessageSubsctriber = callback;
};

export const showSystemMessage = (message: SystemMessage) => {
  systemMessageSubsctriber(message);
};
