import { useContext } from "react";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { ActionType } from "../State/State";

export const AboutGamePopup: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_ABOUT_GAME_POPUP });
  };
  return (
    <BasicPopup onClose={closePopup}>
      <div className="">
        <h1 className="text-2xl font-bold pb-4">About Game</h1>
        <p>
          <b>Hexagen.World</b> is a collectively generated game world created by
          all players.
        </p>
        <p className="py-4 font-bold">The rules are simple: </p>
        <ul className="list-disc pl-4 mb-4">
          <li>
            Come up with a prompt and generate a piece of the world in a free
            hexagon.
          </li>
          <li>
            Your hexagon will be evaluated by AI in creativity points for the
            creativity you've shown.
          </li>
          <li>
            Players earn points for each view of their hexagon by another
            registered player, starting from the third view. The bonus equals
            the creativity score of the prompt.
          </li>
          <li>Each player receives 1 bonus point every hour.</li>
        </ul>
        <p>
          <b>Attention! </b>This is an early preview version, so generations
          take up to 30 seconds, some things may not work, and the functionality
          is not complete.
        </p>
        <p className="font-bold my-4">
          In the future, you will be able to use points to:{" "}
        </p>
        <ul className="list-disc pl-4 mb-4">
          <li>Capture other players' hexagons.</li>
          <li>Attach links to your social networks to hexagons. </li>
          <li>Apply animations. </li>
          <li>Improve the quality of generations. </li>
          {/* <li>Mint hexagons as NFTs.</li> */}
        </ul>
        <p className="mb-4">And much more.</p>
        <p>
          This is a non-commercial project by <b>Mikhail Dunaev.</b>
        </p>
        <p>
          Follow me on{" "}
          <a
            href="https://www.linkedin.com/in/mikhail-dunaev/"
            rel="noreferrer"
            target="_blank"
          >
            LinkedIn
          </a>{" "}
          to learn about updates and other projects!
        </p>
        <p className="mt-4">Made in Estonia 🇪🇪 with 💙</p>
      </div>
    </BasicPopup>
  );
};
