import { useEffect, useState } from "react";
import { subscribeToSystemMessages } from "../utils/api";

export const SystemMessage = () => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  useEffect(() => {
    subscribeToSystemMessages((message) => {
      setMessage(message.text);
      setMessageType(message.systemMessage);
      setTimeout(() => setMessage(""), 5000);
    });
  });

  const classes =
    messageType === "error"
      ? "border-red-600 text-red-600"
      : "border-green-600 text-green-600";
  return message ? (
    <div
      className={
        classes +
        " absolute border-2 animate-bounce top-10 right-10 left-10 bg-white p-3 opacity-95 z-[100]"
      }
    >
      {message}
    </div>
  ) : null;
};
