import { useEffect, useState } from "react";

// emoji animated moon component
export const Moon: React.FC = () => {
  const moons = ["🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗", "🌘"];
  const [moonPhase, setMoonPhase] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMoonPhase((moonPhase + 1) % moons.length);
    }, 100);

    return () => clearInterval(interval);
  }, [moonPhase]);

  return <span className="text-xl">{moons[moonPhase]}</span>;
};
