import { useContext } from "react";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";

export const ZoomController = () => {
  const { state, dispatch } = useContext(AppContext);
  const zoomIn = () => {
    dispatch({ type: ActionType.ZOOM_IN, value: 0.1 });
  };
  const zoomOut = () => {
    dispatch({ type: ActionType.ZOOM_OUT, value: 0.1 });
  };
  return (
    <div className="absolute right-4 top-16 select-none cursor-pointer">
      <div
        onClick={zoomIn}
        className="antique text-center px-2 py-1 bg-black text-black"
        style={{ opacity: state.worldScale === 1.3 ? 0.5 : 1 }}
      >
        +
      </div>
      <div className="text-xs mt-2 opacity-70">
        {state.worldScale.toFixed(2)}
      </div>
      <div
        onClick={zoomOut}
        className="antique text-center px-2 py-1 bg-black text-black mt-2"
        style={{ opacity: state.worldScale === 0.7 ? 0.5 : 1 }}
      >
        -
      </div>
    </div>
  );
};
