import { useContext, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";
import { hexWidth, hexHeight } from "../World/World";
import { RequestMethod, askAPI } from "../utils/api";
import { img } from "../utils/settings";
import { Hexagen, Player } from "../types";
import { BasicPopup } from "./BasicPopup";
import { getTimeAgo } from "../utils/misc";

export const PlayerInfoPopup: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const [playerInfo, setPlayerInfo] = useState<Player | null>(null);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
  };

  const fetchPlayerInfo = async () => {
    const uid = state.selectedPlayerUID;
    if (uid) {
      playerInfo && setPlayerInfo({ ...playerInfo, hexagens: [] });

      const playerInfoUpdated = await askAPI<Player>(
        `/api/player/info/${uid}/${page}?sortBy=${sortBy}&order=${order}`,
        RequestMethod.GET
      );

      playerInfoUpdated &&
        setPlayerInfo({
          ...playerInfoUpdated,
          hexagens: playerInfoUpdated.hexagens.map((hexagen: any) =>
            JSON.parse(hexagen)
          ),
        });
    }
  };

  useAsyncEffect(fetchPlayerInfo, [page, sortBy, order]);

  const onClickOnHex = (hexagen: Hexagen) => {
    dispatch({ type: ActionType.SELECT_HEX, x: hexagen.x, y: hexagen.y });
    dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -hexagen.x * hexWidth * state.worldScale,
      y: -(hexagen.y - 2) * hexHeight * state.worldScale,
      logggable: true,
    });
  };

  const handleSortChange = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSortBy(newSortBy);
      setOrder("desc");
    }
    setPage(0);
  };

  const hexagens = playerInfo ? playerInfo.hexagens : [];

  return (
    <BasicPopup onClose={closePopup}>
      <div>
        {playerInfo ? (
          <>
            <div className="font-bold text-lg mt-3 mb-2">{playerInfo.name}</div>
            <div className="text-sm">{playerInfo.points} pts</div>
            <div className="mb-4 text-sm">
              {playerInfo.hexagensNumber} hexagens
            </div>
            <div className="mb-4 select-none">
              Sort by:{" "}
              <span
                onClick={() => handleSortChange("createdAt")}
                className={`cursor-pointer mr-4 ${
                  sortBy === "createdAt" ? "font-bold" : ""
                }`}
              >
                Date
                {sortBy === "createdAt" ? (order === "asc" ? "▲" : "▼") : ""}
              </span>
              <span
                onClick={() => handleSortChange("views")}
                className={`cursor-pointer mr-4 ${
                  sortBy === "views" ? "font-bold" : ""
                }`}
              >
                Views{sortBy === "views" ? (order === "asc" ? "▲" : "▼") : ""}
              </span>
              <span
                onClick={() => handleSortChange("points")}
                className={`cursor-pointer ${
                  sortBy === "points" ? "font-bold" : ""
                }`}
              >
                Points
                {sortBy === "points" ? (order === "asc" ? "▲" : "▼") : ""}
              </span>
            </div>
            <div className="grid grid-cols-1 gap-4">
              {hexagens.map((hexagen: Hexagen, i: number) => (
                <div key={i} className="flex overflow-x-hidden">
                  <img
                    src={img(`x${hexagen.x}y${hexagen.y}.png`)}
                    alt="hexagen"
                    width={160}
                    onClick={() => onClickOnHex(hexagen)}
                    className="cursor-pointer"
                  />
                  <div className="text-xs pl-6 pt-4">
                    <div>
                      {hexagen.points} points & {hexagen.views} views
                    </div>
                    <div className="my-2">
                      Prompt: <span className="text-sm">{hexagen.prompt}</span>
                    </div>
                    <div>
                      {getTimeAgo(new Date(hexagen.createdAt).toISOString())}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4 flex items-center justify-center select-none text-lg text-center h-10">
              <div
                onClick={() => page > 0 && setPage(Math.max(0, page - 1))}
                className={`px-2 py-1 cursor-pointer flex items-center translate-y-[-3px] ${
                  page === 0 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                ◀
              </div>
              <span className="text-xs flex items-center mx-2 text-center">
                Page {page + 1} from {Math.ceil(playerInfo.hexagensNumber / 16)}{" "}
              </span>
              <div
                onClick={() => hexagens.length >= 16 && setPage(page + 1)}
                className={`px-2 py-1 cursor-pointer flex items-center translate-y-[-3px] ${
                  hexagens.length < 16 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                ▶
              </div>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </BasicPopup>
  );
};
