export interface Player {
  uid: string;
  name: string;
  points: number;
  hexagens: Hexagen[];
  role: "admin" | "player";
  hexagensNumber: number;
}

export interface PlayerProfileProps {
  player: Player;
}

export interface SystemMessage {
  systemMessage: "error" | "success";
  text: string;
}

export interface Hexagen {
  status: HexagenStatus;
  uid: string;
  x: number;
  y: number;
  creator: string;
  owner: string;
  description: string;
  points: number;
  harvestedAt: number;
  commentsNum: number;
  createdAt: number;
  harvestedPoints: number;
  prompt: string;
  views: number;
  upvotes: number;
}

export enum HexagenStatus {
  GENERATING = "generating",
  READY = "ready",
}
