import React, { useContext, useState } from "react";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import { RequestMethod, askAPI } from "../utils/api";
import { rootURL } from "../utils/settings";
import { Player } from "../types";

export const PlayerProfile: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const player = state.player;

  const [newName, setNewName] = useState(player ? player.name : "");

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  };

  const handleUpdatePlayer = async () => {
    const updatedPlayer = await askAPI<Player>(
      "/api/player/edit",
      RequestMethod.POST,
      { name: newName }
    );
    if (updatedPlayer) {
      dispatch({ type: ActionType.UPDATE_PLAYER, player: updatedPlayer });
    }
  };

  const showDeletePopup = async () => {
    dispatch({ type: ActionType.TOGGLE_DELETE_POPUP });
  };

  return (
    player && (
      <div>
        <div className="font-bold mb-4">Player Settings:</div>
        <div>Name: {player.name}</div>
        <div>Points: {player.points}</div>
        <div className="flex flex-col items-start space-y-1 mt-4">
          <label htmlFor="newName" className="font-bold">
            Change Name:
          </label>
          <input
            type="text"
            id="newName"
            value={newName}
            onChange={handleNameChange}
            className="border border-black px-2 py-1 focus:outline-none"
          />
          <button onClick={handleUpdatePlayer}>Update</button>
        </div>
        <button className="my-5" onClick={showDeletePopup}>
          Delete My Account
        </button>
        <br />
        <a href={rootURL + "/api/auth/logout/"} className="button">
          Logout
        </a>
      </div>
    )
  );
};
