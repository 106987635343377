import { useContext } from "react";
import useAsyncEffect from "use-async-effect";
import { PlayerProfile } from "../PlayerProfile/PlayerProfile";
import { ActionType } from "../State/State";
import { RequestMethod, askAPI } from "../utils/api";
import { Player } from "../types";
import { BasicPopup } from "./BasicPopup";
import { AppContext } from "../State/Context";

export const PlayerProfilePopup: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);

  useAsyncEffect(async () => {
    const player = await askAPI<Player>("/api/player", RequestMethod.GET);
    dispatch({ type: ActionType.UPDATE_PLAYER, player });
  }, []);

  const toggleMenu = () => {
    dispatch({ type: ActionType.TOGGLE_PLAYER_PROFILE_POPUP });
  };

  return state.isPlayerProfilePopupOpen ? (
    <BasicPopup onClose={toggleMenu}>
      <PlayerProfile />
      <ConfirmDeleteAccountPopup />
    </BasicPopup>
  ) : null;
};

export const ConfirmDeleteAccountPopup: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const handleDelete = async () => {
    try {
      await askAPI("/api/player/delete", RequestMethod.POST);
      window.location.href = "/";
    } catch (error) {
      console.error("Failed to delete player", error);
    }
  };

  const closeDeletePopup = () => {
    dispatch({ type: ActionType.TOGGLE_DELETE_POPUP });
  };

  return state.isConfirmDeletePopupOpen ? (
    <BasicPopup onClose={closeDeletePopup}>
      <div className="mb-3 font-bold">
        Are you sure you want to delete
        <br /> your account?
      </div>
      <div className="mb-3">
        Please note that this action is irreversible, and you will not be able
        to register again using the same email address. <br /> Proceed with
        caution, as this decision cannot be undone.
      </div>
      <button
        onClick={handleDelete}
        className="button mt-2 bg-red-900 border-red-900 text-white"
      >
        Yes, delete my account
      </button>
      <button onClick={closeDeletePopup} className="button mt-2">
        No, keep my account
      </button>
    </BasicPopup>
  ) : null;
};
