import { createContext, Dispatch, ReactNode, useReducer } from "react";
import { blankState, AppState, AppAction, reducer, ActionType } from "./State";
import { compareObjects } from "./stateUtils";
import { hexHeight, hexWidth } from "../World/World";

// NOTHING TO CHANGE BELOW THIS LINE
const getLocalState = () => {
  const localStateSource = localStorage.getItem("state");
  const localStateJSON = localStateSource ? JSON.parse(localStateSource) : null;
  return localStateJSON?.hash === blankState.hash ? localStateSource : null;
};

const localState = getLocalState();
let initialState: AppState = localState ? JSON.parse(localState) : blankState;

initialState.isLoginPopupOpen = false;
initialState.isPlayerProfilePopupOpen = false;
initialState.generatingNowHexs = [];
initialState.isHexInfoPopupOpen = false;

const path = window.location.pathname;
const regex = /^\/hex\/(-?\d+)\/(-?\d+)/;
const match = path.match(regex);

if (match) {
  const x = parseInt(match[1]);
  const y = parseInt(match[2]);
  initialState = reducer(initialState, { type: ActionType.SELECT_HEX, x, y });
  initialState.isHexInfoPopupOpen = true;
  initialState.worldX = -x * hexWidth * initialState.worldScale;
  initialState.worldY = -(y - 2) * hexHeight * initialState.worldScale;
}

export const AppContext = createContext<{
  state: AppState;
  dispatch: Dispatch<AppAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const loggedReducer = (state: AppState, action: AppAction) => {
  const updatedState = reducer(state, action);
  action.logggable &&
    console.log(
      `\nACTION:\n${JSON.stringify(
        action,
        null,
        2
      )}\n\nCHANGES:\n${compareObjects(state, updatedState)}\n `
    );
  return updatedState;
};

interface AppProviderProps {
  children: ReactNode;
}
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(loggedReducer, initialState);

  localStorage.setItem("state", JSON.stringify(state));

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
