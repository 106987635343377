import React, { useContext, useState } from "react";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";
import { BasicPopup } from "./BasicPopup";
import { Hexagen, Player } from "../types";
import { askAPI, RequestMethod, showSystemMessage } from "../utils/api";
import useAsyncEffect from "use-async-effect";
import { img } from "../utils/settings";
import { hexWidth, hexHeight } from "../World/World";

enum RatingType {
  MOST_VIEWED = "most_viewed",
  MOST_VIEWED_LAST_WEEK = "most_viewed_last_week",
  RECENTLY_CREATED = "last_created",
  TOP_PLAYERS = "top_players",
  MOST_UPVOTED_LAST_WEEK = "most_upvoted_last_week",
  HIGHEST_POINTS_LAST_WEEK = "highest_points_last_week",
}

const ratingAPI = {
  [RatingType.MOST_VIEWED]: "/api/hexagen/most-viewed",
  [RatingType.MOST_VIEWED_LAST_WEEK]: "/api/hexagen/most-viewed-last-week",
  [RatingType.RECENTLY_CREATED]: "/api/hexagen/recent",
  [RatingType.TOP_PLAYERS]: "/api/player/top",
  [RatingType.MOST_UPVOTED_LAST_WEEK]: "/api/hexagen/most-upvoted-last-week",
  [RatingType.HIGHEST_POINTS_LAST_WEEK]:
    "/api/hexagen/highest-points-last-week",
};

export const RatingsPopup: React.FC<{}> = () => {
  const { dispatch, state } = useContext(AppContext);
  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_MOST_VIEWED_POPUP });
  };

  const [ratingType, setRatingType] = useState<RatingType>(
    RatingType.MOST_UPVOTED_LAST_WEEK
  );

  useAsyncEffect(async () => {
    const apiUrl = ratingAPI[ratingType];

    const ratingData = await askAPI<string[]>(apiUrl, RequestMethod.GET);

    if (ratingData) {
      switch (ratingType) {
        case RatingType.MOST_VIEWED:
          dispatch({
            type: ActionType.SET_MOST_VIEWED_RATING,
            hexagens: ratingData.map((hex) => JSON.parse(hex) as Hexagen),
          });
          break;
        case RatingType.MOST_VIEWED_LAST_WEEK:
          dispatch({
            type: ActionType.SET_MOST_VIEWED_LAST_WEEK_RATING,
            hexagens: ratingData.map((hex) => JSON.parse(hex) as Hexagen),
          });
          break;
        case RatingType.HIGHEST_POINTS_LAST_WEEK:
          dispatch({
            type: ActionType.SET_HIGHEST_POINTS_LAST_WEEK_RATING,
            hexagens: ratingData.map((hex) => JSON.parse(hex) as Hexagen),
            logggable: true,
          });
          break;
        case RatingType.MOST_UPVOTED_LAST_WEEK:
          dispatch({
            type: ActionType.SET_MOST_UPVOTED_LAST_WEEK_RATING,
            hexagens: ratingData.map((hex) => JSON.parse(hex) as Hexagen),
          });
          break;
        case RatingType.RECENTLY_CREATED:
          dispatch({
            type: ActionType.SET_LAST_CREATED_RATING,
            hexagens: ratingData.map((hex) => JSON.parse(hex) as Hexagen),
          });
          break;
        case RatingType.TOP_PLAYERS:
          dispatch({
            type: ActionType.SET_TOP_PLAYERS_RATING,
            players: ratingData.map((player) => JSON.parse(player)),
          });
          break;
      }
    } else {
      showSystemMessage({
        text: "Failed to get most viewed hexes. API:666",
        systemMessage: "error",
      });
    }
  }, [ratingType]);

  const onClickOnHex = (hexagen: Hexagen) => {
    dispatch({ type: ActionType.SELECT_HEX, x: hexagen.x, y: hexagen.y });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({ type: ActionType.TOGGLE_MOST_VIEWED_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -hexagen.x * hexWidth * state.worldScale,
      y: -(hexagen.y - 2) * hexHeight * state.worldScale,
      logggable: true,
    });
  };

  const changeRatingType = (type: RatingType) => {
    setRatingType(type);
    console.log("Rating type changed to:", type);
  };

  const getTailwindClassesForSelectedRatingType = (type: RatingType) => {
    if (ratingType === type) {
      return "font-bold text-lg select-none";
    }
    return "hover:underline cursor-pointer";
  };

  const getHexsForRating = () => {
    switch (ratingType) {
      case RatingType.MOST_VIEWED:
        return state.ratings.mostViewed;
      case RatingType.MOST_VIEWED_LAST_WEEK:
        return state.ratings.mostViewedLastWeek;
      case RatingType.HIGHEST_POINTS_LAST_WEEK:
        return state.ratings.highestPointsLastWeek;
      case RatingType.MOST_UPVOTED_LAST_WEEK:
        return state.ratings.mostUpvotedLastWeek;
    }
    return state.ratings.lastCreated;
  };

  const createPlayerRating = () => {
    const clickOnPlayer = (uid: string) => {
      if (uid) {
        dispatch({ type: ActionType.SELECT_PLAYER, uid });
        dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
        dispatch({ type: ActionType.TOGGLE_MOST_VIEWED_POPUP });
      }
    };

    return state.ratings.topPlayers.map((player: Player, i: number) => {
      return (
        <div key={i} className="mb-4">
          <div
            className="font-bold cursor-pointer underline"
            onClick={() => clickOnPlayer(player.uid)}
          >
            {player.name}
          </div>
          <div>points: {player.points}</div>
        </div>
      );
    });
  };

  const createHexRating = () => {
    const ratingHexagens = getHexsForRating();

    console.log("ratingHexagens", ratingHexagens);
    return ratingHexagens.map((hex, i) => (
      <div
        key={i}
        onClick={() => onClickOnHex(hex)}
        className="flex hover:bg-stone-200 cursor-pointer"
      >
        <div className="flex-shrink-0 w-[150px] sm:w-[220px]">
          <img
            src={img(`x${hex.x}y${hex.y}@big.png`)}
            alt="hexagen"
            className="max-h-[220px]"
          />
        </div>
        <div className="pl-6 pt-4">
          <div className="text-xs mb-1">
            x: {hex.x} y: {hex.y}
          </div>
          <div className="font-bold mb-3">{hex.prompt}</div>
          <div>upvotes: {hex.upvotes}</div>
          <div>views: {hex.views}</div>
          <div>points: {hex.points}</div>
        </div>
      </div>
    ));
  };

  const rating =
    ratingType === RatingType.TOP_PLAYERS
      ? createPlayerRating()
      : createHexRating();

  return (
    <BasicPopup onClose={closePopup}>
      <div className="font-bold text-lg mb-6">Ratings</div>
      <div
        className={getTailwindClassesForSelectedRatingType(
          RatingType.TOP_PLAYERS
        )}
        onClick={() => changeRatingType(RatingType.TOP_PLAYERS)}
      >
        Top active players
      </div>
      <div
        className={getTailwindClassesForSelectedRatingType(
          RatingType.RECENTLY_CREATED
        )}
        onClick={() => changeRatingType(RatingType.RECENTLY_CREATED)}
      >
        Recently Created
      </div>
      <div
        className={getTailwindClassesForSelectedRatingType(
          RatingType.MOST_VIEWED_LAST_WEEK
        )}
        onClick={() => changeRatingType(RatingType.MOST_VIEWED_LAST_WEEK)}
      >
        Most viewed last week
      </div>
      <div
        className={getTailwindClassesForSelectedRatingType(
          RatingType.HIGHEST_POINTS_LAST_WEEK
        )}
        onClick={() => changeRatingType(RatingType.HIGHEST_POINTS_LAST_WEEK)}
      >
        Highest points last week
      </div>
      <div
        className={getTailwindClassesForSelectedRatingType(
          RatingType.MOST_UPVOTED_LAST_WEEK
        )}
        onClick={() => changeRatingType(RatingType.MOST_UPVOTED_LAST_WEEK)}
      >
        Most upvoted last week
      </div>
      <div
        className={
          getTailwindClassesForSelectedRatingType(RatingType.MOST_VIEWED) +
          " mb-6"
        }
        onClick={() => changeRatingType(RatingType.MOST_VIEWED)}
      >
        Most viewed
      </div>
      {rating}
    </BasicPopup>
  );
};
