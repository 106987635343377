import { useContext } from "react";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";
import { rootURL } from "../utils/settings";

export const MenuPopup = () => {
  const { state, dispatch } = useContext(AppContext);
  const closeMenu = () => {
    dispatch({ type: ActionType.TOGGLE_MENU_POPUP });
  };

  const clickOnAboutGame = () => {
    dispatch({ type: ActionType.TOGGLE_ABOUT_GAME_POPUP });
  };

  const clickOnPlayerInfo = () => {
    const uid = state.player?.uid;
    if (uid) {
      dispatch({ type: ActionType.SELECT_PLAYER, uid });
      dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
    }
  };

  const clickOnSettings = () => {
    dispatch({ type: ActionType.TOGGLE_PLAYER_PROFILE_POPUP });
  };

  const clickOnRedeem = () => {
    dispatch({ type: ActionType.TOGGLE_REDEEM_POPUP });
  };

  return (
    <div
      onClick={closeMenu}
      className="fixed bg-black bg-opacity-40 shadow inset-0 flex justify-center items-start p-3 pb-6 sm:p-0"
    >
      <div className="antique shadow-sm border-black fixed top-14 right-2 p-4">
        <div
          onClick={clickOnAboutGame}
          className="hover:bg-black hover:text-amber-100 underline p-3 pb-1 cursor-pointer"
        >
          About Game
        </div>
        <div
          onClick={clickOnPlayerInfo}
          className="hover:bg-black hover:text-amber-100 underline p-3 pb-1 cursor-pointer"
        >
          Player Info
        </div>
        <div
          onClick={clickOnSettings}
          className="hover:bg-black hover:text-amber-100 underline p-3 pb-1 cursor-pointer"
        >
          Settings
        </div>
        <div
          onClick={clickOnRedeem}
          className="hover:bg-black hover:text-amber-100 underline p-3 pb-1 cursor-pointer"
        >
          Redeem Code
        </div>
        <div className="hover:bg-black hover:text-amber-100 underline p-3 pb-1 cursor-pointer">
          <a href={rootURL + "/api/auth/logout/"}>Logout</a>
        </div>
      </div>
    </div>
  );
};
