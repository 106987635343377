import { useContext, useState, useRef, useEffect } from "react";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";

export const PlayBtn = () => {
  const { state, dispatch } = useContext(AppContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const animationRef = useRef<number | null>(null);
  const currentWorldX = useRef(state.worldX);

  const animate = () => {
    currentWorldX.current -= 1;
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: currentWorldX.current,
      y: state.worldY,
    });

    animationRef.current = window.setTimeout(() => {
      if (isPlaying) {
        animate();
      }
    }, 25);
  };

  useEffect(() => {
    if (isPlaying) {
      animate();
    } else if (animationRef.current !== null) {
      clearTimeout(animationRef.current);
    }

    return () => {
      if (animationRef.current !== null) {
        clearTimeout(animationRef.current);
      }
    };
  }, [isPlaying]);

  useEffect(() => {
    currentWorldX.current = state.worldX;
  }, [state.worldX]);

  const onClick = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <span onClick={onClick} className="mx-2 cursor-pointer select-none">
      {isPlaying ? "⏸" : "⏵"}
    </span>
  );
};
