import { useContext } from "react";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";
import { BasicPopup } from "./BasicPopup";
import useAsyncEffect from "use-async-effect";
import { askAPI, RequestMethod } from "../utils/api";
import { Hexagen } from "../types";
import { img } from "../utils/settings";

export const GeneratedPopupAward: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const selectedHexInfo = state.selectedHexInfo;

  useAsyncEffect(async () => {
    if (selectedHexInfo) {
      const hexInfo = await askAPI<Hexagen>(
        "/api/hexagen/info",
        RequestMethod.GET,
        {
          x: selectedHexInfo.x,
          y: selectedHexInfo.y,
        }
      );
      if (hexInfo) {
        dispatch({ type: ActionType.ADD_SELECTED_HEX_INFO, hexInfo });
      }
    }
  }, [selectedHexInfo?.x, selectedHexInfo?.y]);

  if (!selectedHexInfo) {
    return null;
  }

  const { x, y, points, prompt } = selectedHexInfo;

  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_GENERATED_AWARD_POPUP });
  };

  return state.isGeneratedAwardPopupOpen ? (
    <BasicPopup onClose={closePopup}>
      <div className="text-xl font-bold text-center my-3">Congratulation!</div>
      <div className="text-lg text-center">
        You have successfully generated a hexagon 🎉
      </div>
      <div className="text-sm my-2 text-center">
        x:{x} y:{y}
      </div>
      <div className=" text-center">
        Prompt: <span className="font-bold">{prompt}</span>
      </div>
      <img
        src={img(`x${x}y${y}@big.png`)}
        alt="hexagen"
        className="my-1 max-h-[300px]  mx-auto"
      />

      <div className="mb-6 text-center text-lg">
        The creativity of your prompt was evaluated by the Artificial
        Intelligence and awarded
        <span className="font-bold text-xl mx-auto"> {points} points.</span>
      </div>

      <div className="my-4">
        <div className="my-4">
          This hexagon will produce
          <span className="font-bold"> {points / 10} points </span>daily, which
          will accumulate over time.
        </div>
        <div className="text-sm">
          You can harvest these points and use them to:
          <ul>
            <li>- Generate new hexagons </li>
            <li>- Regenerate existing hexagons</li>
            <li>- Capture hexagons belonging to other players</li>
          </ul>
        </div>
      </div>
    </BasicPopup>
  ) : null;
};
